<template>
  <div class="row">
    <div class="col-12">
      <div class="row justify-content-center">
        <div class="col-12 mb-3">
          <div class="card w-100 bg-lightblue p-lg-4 pt-lg-5 border-0 rounded-lg d-block float-left">
            <img src="/images/profile.png" alt="icon" class="sm-mt-2 w75 position-relative top--10 float-left mr-2 mt--1 ">
            <h2 class="font-lg d-inline-block float-left mb-0 text-grey-900 fw-700">
                <span class="font-xssss fw-600 text-grey-500 d-block mb-2 ml-1">{{user.email}}</span>{{user.name}}
            </h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="navtabs0">
                    <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row m-0 p-0">    
                                    <div class="form-group col-12 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <select @change="cycleChange()" v-model="cycle" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100">
                                            <option value="1">Cycle 1</option>
                                            <option value="2">Cycle 2</option>
                                            <option value="3">Cycle 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isLoad" class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row text-black py-5">
                          <div class="col-12 d-flex align-items-center justify-content-center mb-3">
                              <img src="/images/logo-1-new.png" width="40">
                          </div>
                          <div class="col-12 text-center mb-3">
                              <b><i>Elingway: Learning Any Language Differenly</i></b>
                          </div>
                          <div class="col-12 text-center mb-3">
                              <b>Assessment Progress Report</b>
                          </div>
                          <div class="col-12 text-left mb-4">
                              <table class="table table-bordered text-black">
                                  <thead>
                                      <tr>
                                          <td width="20%">Type Of Progress Report</td>
                                          <td>Cycle {{cycle}}</td>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>Name of Course Category</td>
                                          <td>{{report.course_name}}</td>
                                      </tr>
                                      <tr>
                                          <td>Name of Student</td>
                                          <td>{{report.user_name}}</td>
                                      </tr>
                                      <tr>
                                          <td>Student University</td>
                                          <td>{{report.user_university}}</td>
                                      </tr>
                                      <tr>
                                          <td>Date of Report</td>
                                          <td>
                                            <input type="date" v-model="report.date_report" class="form-control">
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                          <div class="col-12 text-center mb-4">
                              <b>Measurable Short-term Objectives</b>
                          </div>
                          <div v-if="report.id" class="col-12 text-left mb-1">
                              <table class="table table-bordered text-black">
                                  <thead>
                                      <tr>
                                          <td width="25%">CEFR Mapping Objective</td>
                                          <td>Descriptor</td>
                                          <td>Materials</td>
                                          <td>Assessment Tasks</td>
                                          <td>CEFR Level Overview</td>
                                          <td>Final Remarks</td>
                                          <td>Meaning</td>
                                          <td>Option</td>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, index) in report.objectives" :key="index">
                                      <td>{{item.mapping_objective_component}}</td>
                                      <td>{{item.mapping_objective_descriptor}}</td>
                                      <td>{{item.material_title}}</td>
                                      <td>{{item.assignment_title}}</td>
                                      <td>{{item.level_overview_level}}</td>
                                      <td>{{item.level_overview_final_remarks}}</td>
                                      <td>{{item.level_overview_meaning}}</td>
                                      <td><button @click="deleteObjective(item.id)" class="btn btn-block btn-danger"><i class="fas fa-trash"></i></button></td>
                                    </tr>
                                    <tr v-if="report.objectives.length == 0" >
                                      <td colspan="8" class="text-center bg-grey">No Objectives</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <select @change="setMapping()" v-model="form_objective.mapping_objective_id" class="form-control">
                                          <option value="" disabled>Select Mapping Objective</option>
                                          <option v-for="(item, index) in insertion.mapping_objectives" :key="index" :value="item.id">{{item.component}}</option>
                                        </select>
                                      </td>
                                      <td>{{form_objective.mapping_objective_descriptor}}</td>
                                      <td>
                                        <select v-model="form_objective.material_id" class="form-control">
                                          <option value="" disabled>Select Material</option>
                                          <option v-for="(item, index) in insertion.materials" :key="index" :value="item.id">{{item.title}}</option>
                                        </select>
                                      </td>
                                      <td>
                                        <select v-model="form_objective.assignment_id" class="form-control">
                                          <option value="">Select Assessment Task</option>
                                          <option v-for="(item, index) in insertion.assignments" :key="index" :value="item.id">{{item.title}}</option>
                                        </select>
                                      </td>
                                      <td>
                                        <select @change="setLevel()" v-model="form_objective.level_overview_id" class="form-control">
                                          <option value="">Select Level Overview</option>
                                          <option v-for="(item, index) in insertion.level_overviews" :key="index" :value="item.id">{{item.level}}</option>
                                        </select>
                                      </td>
                                      <td>{{form_objective.level_overview_final_remarks}}</td>
                                      <td>{{form_objective.level_overview_meaning}}</td>
                                      <td><button @click="postObjective()" class="btn btn-block btn-success"><i class="fas fa-save"></i></button></td>
                                    </tr>
                                  </tbody>
                              </table>
                          </div>
                          <div v-else class="col-12 text-left mb-1">
                            <table class="table">
                              <tr>
                                <th class="text-center">Please Save Report for insert objectives</th>
                              </tr>
                            </table>
                          </div>
                          <div class="col-12 text-left mb-3">
                              <table class="table table-bordered text-black">
                                  <tbody>
                                      <tr>
                                          <td width="25%">Were Objectives Met?</td>
                                          <td>
                                            <select v-model="report.objective_met" class="form-control">
                                              <option value="">Select Option</option>
                                              <option :value="true">Yes</option>
                                              <option :value="false">No</option>
                                            </select>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td width="25%">If no, why not?</td>
                                          <td>
                                            <textarea v-model="report.not_reason" class="form-control"></textarea>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td width="25%">Additional Comments:</td>
                                          <td>
                                            <textarea v-model="report.comment" class="form-control"></textarea>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                          <div class="col-12 text-left mb-3">
                            <table class="table border-0 text-black">
                              <tr>
                                <td>Authorized Signature: </td>
                                <td>
                                  <file-upload
                                    class="btn btn-lg btn-current mr-1"
                                    @input="updateFile"
                                    @input-file="inputFile"
                                    @input-filter="fileFilter"
                                    :custom-action="fileAction"
                                    accept="image/*"
                                    ref="upload" required>
                                    <i class="fa fa-plus"></i>
                                    Upload
                                  </file-upload>

                                  <a v-if="report.signature" :href=" report.signature" target="_blank" class="btn btn-lg btn-success">Current File</a>

                                  <button type="button" class="btn btn-lg btn-danger ml-1"  v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false">
                                  <i class="fa fa-stop" aria-hidden="true"></i>
                                  Stop Upload
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>Date Sent to Parents: </td>
                                <td>
                                  <input v-model="report.date_send_parent" type="date" class="form-control form-control-sm">
                                </td>
                              </tr>
                              <tr>
                                <td>Date Sent to University: </td>
                                <td>
                                  <input v-model="report.date_send_university" type="date" class="form-control form-control-sm">
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div class="col-12 text-right">
                            <button @click="report.publish = !report.publish" class="btn" :class="{'btn-danger': !report.publish, 'btn-success': report.publish}"><i class="fas mr-2" :class="{'fa-times': !report.publish, 'fa-check': report.publish}"></i>Publish</button>
                            <button @click="generateReport()" class="btn btn-danger ml-2">Export Report</button>
                            <button @click.prevent="sendReport()" class="btn btn-primary ml-2">Save</button>
                          </div>
                      </div>
                      <vue-html2pdf
                        :enable-download="true"
                        filename="hehehe"
                        :pdf-quality="2"
                        pdf-format="a4"
                        pdf-orientation="portrait"
                        :manual-pagination="true"
                        ref="html2Pdf"
                        :html-to-pdf-options="htmlToPdfOptions"
                      >
                        <section slot="pdf-content">
                              <section class="pdf-item">
                                <div class="row text-black font-xssss pt-3">
                                    <div class="col-12 d-flex align-items-center justify-content-center mb-3">
                                        <img src="/images/logo-1-new.png" width="40">
                                    </div>
                                    <div class="col-12 text-center mb-3">
                                    <b><i>Elingway: Learning Any Language Differenly</i></b>
                                    </div>
                                    <div class="col-12 text-center mb-3">
                                        <b>Assessment Progress Report</b>
                                    </div>
                                    <div class="col-12 text-left mb-2">
                                        <table class="table table-bordered text-black">
                                            <thead>
                                                <tr>
                                                    <td width="20%">Type Of Progress Report</td>
                                                    <td>Cycle {{cycle}}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Name of Course Category</td>
                                                    <td>{{report.course_name}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Name of Student</td>
                                                    <td>{{report.user_name}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Student University</td>
                                                    <td>{{report.user_university}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date of Report</td>
                                                    <td>{{report.date_report | formatDate}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-12 text-center mb-2">
                                        <b>Measurable Short-term Objectives</b>
                                    </div>
                                    <div class="col-12 text-left mb-1">
                                        <table class="table table-bordered text-black">
                                            <thead>
                                                <tr>
                                                    <td>CEFR Mapping Objective</td>
                                                    <td>Descriptor</td>
                                                    <td>Materials</td>
                                                    <td>Assessment Tasks</td>
                                                    <td>CEFR Level Overview</td>
                                                    <td>Final Remarks</td>
                                                    <td>Meaning</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(item, index) in report.objectives" :key="index">
                                                <td>{{item.mapping_objective_component}}</td>
                                                <td>{{item.mapping_objective_descriptor}}</td>
                                                <td>{{item.material_title}}</td>
                                                <td>{{item.assignment_title}}</td>
                                                <td>{{item.level_overview_level}}</td>
                                                <td>{{item.level_overview_final_remarks}}</td>
                                                <td>{{item.level_overview_meaning}}</td>
                                            </tr>
                                            <tr v-if="report.objectives.length == 0" >
                                                <td colspan="8" class="text-center bg-grey">No Objectives</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-12 text-left mb-2">
                                        <table class="table table-bordered text-black">
                                            <tbody>
                                                <tr>
                                                    <td width="25%">Were Objectives Met?</td>
                                                    <td><i class="fas" :class="{'fa-check': report.objective_met, 'fa-times': !report.objective_met}"></i></td>
                                                </tr>
                                                <tr>
                                                    <td width="25%">If no, why not?</td>
                                                    <td>{{report.not_reason}}</td>
                                                </tr>
                                                <tr>
                                                    <td width="25%">Additional Comments:</td>
                                                    <td>{{report.comment}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-12 text-left mb-2">
                                    <table class="table border-0 text-black">
                                        <tr>
                                            <td>Authorized Signature: </td>
                                            <td class="text-right"><img v-if="report.signature" :src=" report.signature" alt="..." width="100"></td>
                                        </tr>
                                        <tr>
                                            <td>Date Sent to Parents: </td>
                                            <td class="text-right">{{report.date_send_parent | formatDate}}</td>
                                        </tr>
                                        <tr>
                                            <td>Date Sent to University: </td>
                                            <td class="text-right">{{report.date_send_university | formatDate}}</td>
                                        </tr>
                                    </table>
                                    </div>
                                </div>
                            </section>
                        </section>
                      </vue-html2pdf>
                    </div>
                    <div v-else class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                      <div class="row">
                        <div class="col-12 text-center py-3">
                          <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import FileUpload from 'vue-upload-component'
export default {
  components:{
    FileUpload
  },
  computed: {
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: "Report.pdf",
        image: {
          type: "jpeg",
          quality: 2,
        },
        enableLinks: true,
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: {
          unit: "in",
          format: 'a4',
          orientation: 'portrait',
        },
      };
    },
  },
  data(){
    return {
      isLoad: true,
      slug: this.$route.params.idCourse,
      idUser: this.$route.params.idStudent,
      cycle: 1,
      media: process.env.VUE_APP_URL_CLOUD,
      files: [],
      user: {
        name: '',
        email: '',
      },
      insertion: {
        mapping_objectives: [],
        level_overviews: [],
        materials: [],
        assignments: [],
      },
      form_objective: {
        mapping_objective_id: '',
        mapping_objective_component: '',
        mapping_objective_descriptor: '',
        material_id: '',
        material_title: '',
        assignment_id: '',
        assignment_title: '',
        level_overview_id: '',
        level_overview_level: '',
        level_overview_final_remarks: '',
        level_overview_meaning: '',
      },
      report: {
        id: '',
        course_id: '',
        course_name: '',
        user_id: '',
        user_name: '',
        user_university: '',
        cycle: 1,
        publish: false,
        date_report: '',
        objective_met: true,
        not_reason: '',
        comment: '',
        signature: '',
        date_send_parent: '',
        date_send_university: '',
        objectives: []
      }
    }
  },
  created() {
    this.getUser();
    this.getInsertion();
    this.getReport();
  },
  methods: {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    cycleChange(){
      this.isLoad = true;
      this.getReport();
    },
    setMapping(){
      let data = this.insertion.mapping_objectives.find(x => x.id == this.form_objective.mapping_objective_id);
      this.form_objective.mapping_objective_descriptor = data.descriptor;
    },
    setLevel(){
      let data = this.insertion.level_overviews.find(x => x.id == this.form_objective.level_overview_id);
      this.form_objective.level_overview_final_remarks = data.final_remarks;
      this.form_objective.level_overview_meaning = data.meaning;
    },
    async getUser() {
      await axios.get(`${process.env.VUE_APP_URL_API}/admin/user?id=${this.idUser}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      }).then(res => {
        this.user = res.data
      }).catch(() => {
        this.$swal({
          toast: true,
          title: 'Report',
          text: 'Failed Load User!',
          icon: 'error',
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      })
    },
    async getInsertion() {
      await axios.get(`${process.env.VUE_APP_URL_API}/admin/evaluation/reward-recognition/data-insertion?slug=${this.slug}&cycle=${this.cycle}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      }).then(res => {
        if(res.data.success) {
          this.insertion = res.data.data
          const tempAssignments = this.insertion.assignments
          const sortedData = tempAssignments.sort((a,b) => {
                    const unitA = parseFloat(a.title.match(/(\d+(\.\d+)?)/)[0]);
                    const unitB = parseFloat(b.title.match(/(\d+(\.\d+)?)/)[0]);
                    return unitA - unitB;
                });
          this.insertion.assignments = sortedData
        }
      }).catch(() => {
        this.$swal({
          toast: true,
          title: 'Report',
          text: 'Failed Load Insertion!',
          icon: 'error',
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      })
    },
    async getReport() {
      await axios.get(`${process.env.VUE_APP_URL_API}/admin/evaluation/reward-recognition?slug=${this.slug}&user_id=${this.idUser}&cycle=${this.cycle}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      }).then(res => {
        if(res.data.success) {
          this.report = res.data.data
          this.isLoad = false
        }
      }).catch(() => {
        this.$swal({
          toast: true,
          title: 'Report',
          text: 'Failed Load Syllabus!',
          icon: 'error',
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      })
    },
    async sendReport() {
      var data = this.report
      data.slug = this.slug
      await axios.post(`${process.env.VUE_APP_URL_API}/admin/evaluation/reward-recognition/send`, data, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.token}`
          },
      }).then((res) => {
          this.$swal({
              toast: true,
              title: 'Report!',
              text: res.data.message,
              icon: 'success',
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
          })
          this.getReport()
      }).catch((err) => {
        if (err.response.status == 422){
          this.$swal({
            toast: true,
            title: 'Report!',
            text: err.response.data.message[0],
            icon: 'error',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        }
      })
    },
    async postObjective() {
      var data = this.form_objective
      data.reward_recognition_id = this.report.id
      await axios.post(`${process.env.VUE_APP_URL_API}/admin/evaluation/reward-recognition/objective`, data, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.token}`
          },
      }).then((res) => {
          this.$swal({
              toast: true,
              title: 'Report!',
              text: res.data.message,
              icon: 'success',
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
          })
          this.form_objective = {
            mapping_objective_id: '',
            mapping_objective_component: '',
            mapping_objective_descriptor: '',
            material_id: '',
            material_title: '',
            assignment_id: '',
            assignment_title: '',
            level_overview_id: '',
            level_overview_level: '',
            level_overview_final_remarks: '',
            level_overview_meaning: '',
          }
          this.getReport()
      }).catch((err) => {
        if (err.response.status == 422){
          this.$swal({
            toast: true,
            title: 'Report!',
            text: err.response.data.message[0],
            icon: 'error',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        }
      })
    },
    async deleteObjective(id) {
      var data = {
        id: id,
        _method: 'delete'
      }
      await axios.post(`${process.env.VUE_APP_URL_API}/admin/evaluation/reward-recognition/objective`, data, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.token}`
          },
      }).then((res) => {
          this.$swal({
              toast: true,
              title: 'Report!',
              text: res.data.message,
              icon: 'success',
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
          })
          this.getReport()
      }).catch((err) => {
        if (err.response.status == 422){
          this.$swal({
            toast: true,
            title: 'Report!',
            text: err.response.data.message[0],
            icon: 'error',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        }
      })
    },
    updateFile(value) {
      this.files = value
    },
    async inputFile(newFile, oldFile) {
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    fileFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    async fileAction(file) {
      return this.postFile(file)
    },
    async postFile(file){
      let data = new FormData();
      data.append('dir', 'signature');
      data.append('attachment', file.file);
      data.append('_token', 'elingwaysecretkey');
      await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
          headers: {
              'Accept': 'application/json',
              'content-type': 'multipart/form-data',
              'Authorization': `Bearer ${localStorage.token}`
          },
      }).then(res => {
          file.response = res.data
          this.report.signature = res.data.path
          return res.data
      })
    },
  }
}
</script>